<template>
  <div v-if="simpleTpl" class="store">
      <div class="item">
        <TwoLevelListDTS :tableData="tableDataDTS"></TwoLevelListDTS>
      </div>
  </div>
  <div v-else class="store">
      <div class="item">
        <div class="title">得分公式</div>
        <div class="box-total" v-if="scoreData">
          <p>
          <strong class="a">{{scoreData.a}}</strong>
          <span class="a-p">展厅接待得分<i>(A)</i></span>
          </p>
          <p class="flag">=</p>
          <p>
          <strong>{{scoreData.b}}</strong>
          <span>系统使用得分 <i class="i-question" @click="popFormula=true"></i> <i>B ({{scoreData.b_percent}})</i></span>
          </p>
          <p class="flag">+</p>
          <p>
          <strong>{{scoreData.c}}</strong>
          <span>接待质量得分<i>C ({{scoreData.c_percent}})</i></span>
          </p>
        </div>
      </div>
      <div class="line"></div>
      <div class="item">
        <div class="title">核心指标</div>
        <div class="box-core">
          <div class="main" v-for="(item, index) in entryData" :key="index" >
            <div class="value">
              <strong>{{ item.value }}</strong> {{['组','组','组','分'][index]}}
            </div>
            <div class="label">
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="item">
        <div class="title">销售顾问排名</div>
        <ul class="list-top">
          <li v-for="(item, idx) in topData">
            <span class="label"><strong>{{idx + 1}}</strong>{{item.label}}</span>
            <div class="box-bar">
              <span class="bara">
              </span>
              <span class="bar"
                :style="`width: ${item.value}%`">
              </span>
            </div>
            <span class="score">{{item.value}}</span>
          </li>
        </ul>
      </div>
      <div class="line"></div>
      <div class="item">
        <div class="title">接待环节质量</div>
        <div class="detail">
          <!--
          <div ref="chartBar" style="width: 100%; height: 5.86666rem" class="chart" ></div>
          -->
          <div ref="chart" style="width: 370px; height: 280px; margin: auto;"></div>
        </div>
      </div>

      <div class="line"></div>
      <div class="item">
        <ThreeLevelList v-if="threeTpl" :tableData="tableData" :tableQualityData="tableQualityData"></ThreeLevelList>
        <TwoLevelList v-else :tableData="tableData"></TwoLevelList>
      </div>

      <div class="item">
        <div class="title" style="margin-top: 0.53333rem">试驾员工检核</div>
        <div class="box-drive">
          <div class="tbl-drive">
            <a-table rowKey="index"
              :columns="columns" 
              :data-source="driveList"
              :rowClassName="setRowClass"
              :pagination="false">

              <div v-if="!record.id" slot="name" slot-scope="text,record,index">{{text}}</div>
              <a v-else class="tbl-name" slot="name" slot-scope="text,record,index"
               @click="jumpToDrive(record.id)">
                <strong>{{index}}</strong> {{text}}
              </a>
            </a-table>
          </div>
      </div>
      </div>
      <el-dialog
        title=" "
        :visible.sync="popFormula"
        class="pop-formula" >
         <div class="box-formula">
           <p>{{formula.b_formula}}</p>
           <!--
           <p>
             <span>人员使用得分=</span>
             <span class="division">
               <strong>{{formula.b1_numerator}}</strong>
               <strong>{{formula.b1_denominator}}</strong>
             </span>
             <span>{{formula.b1_right}}</span>
           </p>
           <p>
             <span>人员匹配得分=</span>
             <span class="division">
               <strong>{{formula.b2_numerator}}</strong>
               <strong>{{formula.b2_denominator}}</strong>
             </span>
             <span>{{formula.b2_right}}</span>
           </p>
           -->
         </div>
        <span slot="footer" class="dialog-footer">
        </span>
      </el-dialog>
  </div>
</template>
<script>
import { postData } from "@/dbCommon/api/index";
import echarts from "echarts";
import util from "@/dbCommon/utils/index";
import TwoLevelList from '@/components/TwoLevelList'
import TwoLevelListDTS from '@/components/TwoLevelListDTS'
import ThreeLevelList from '@/components/ThreeLevelList'

export default {
  components: {
    TwoLevelList,
    TwoLevelListDTS,
    ThreeLevelList,
  },

  data() {
    return {
      driveList: [],
      entryData: [],
      listData: [],
      scoreData: {},
      topData: [],
      formula: {},
      popFormula: false,
      tableData: {},
      tableDataDTS: {},
      simpleTpl: util.isDTS,
      tableQualityData: [],
      threeTpl: util.showThree,
      columns: [
        {
          title: '试驾顾问',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '试驾量 (次)',
          align: 'center',
          dataIndex: 'hall_count',
        },
        {
          title: '平均试驾时长 (分钟)',
          align: 'center',
          dataIndex: 'hall_time',
        },
        {
          title: '试驾得分',
          align: 'center',
          dataIndex: 'hall_score',
        },
      ]
      
    }
  },
  methods: {
    getQuery() {
      const query = { is_mobile:1,...this.$route.query, ...this.$store.state.queryDB };
      return query;
    },
    setRowClass(record, index) {
      return !record.id ? 'noid' : '';
    },
    jumpToDrive(id) {
      if (!id) {
        return
      }
      const routeUrl = this.$router.resolve({
        name: 'driveList',
        query: {...this.getQuery(), admin_id: id}
      });
      window.open(routeUrl.href, '_blank');
    },
    getQualityData () {
      postData('/api/board/qualityTestingCompany', {
        ...this.getQuery(),
      }).then(res => {
        this.tableQualityData = res.data;
      });
    },
    async getDataBySearch() {
      const query = {
        ...this.getQuery(),
        ...this.$store.state.multi_company_query,
      };
      if(query.brand_id) {
        this.simpleTpl = query.brand_id == util.DTS_ID;
        this.threeTpl = util.showThree && (query.brand_id == util.DAZHONG_ID);
      }
      
      this.loading = true;
      const { data = {} } = await postData("/api/board/seller", query);
      const {
        list,
        title_hall,
        title_web,
        core,
        top10,
        chart,
        formula,
        radarData,
        driveList,
        ...scoreData
      } = data || {};
 
      this.driveList = driveList;
      this.entryData = [
      { label: "接待量", value: core.count },
        { label: "首次进店量", value: core.first },
        { label: "再次进店量", value: core.again },
        { label: "展厅接待得分", value: core.total },
      ];

      this.topData = top10.map(o => ({label: o.name, value: o.total}));
      this.listData = list;
      this.scoreData = scoreData;
      this.formula = formula;

      this.tableData = util.formatVolvoList(list, title_hall);
      this.tableDataDTS = util.formatVolvoListDTS(list, title_hall);

      const barList = [
        {
          title: "接待量",
          dataList: chart.date.map((o, i) => ({
            label: o.slice(-5) + "周" + chart.week[i].slice(-1),
            value: chart.total[i],
          })),
        },
        {
          title: "首次进店量",
          dataList: chart.date.map((o, i) => ({
            label: o + chart.week[i],
            value: chart.first[i],
          })),
        },
        {
          title: "再次进店量",
          dataList: chart.date.map((o, i) => ({
            label: o + chart.week[i],
            value: chart.again[i],
          })),
        },
      ];
      //this.initChart(getBarOpts(barList));

      const valueArr = Object.values(radarData);
      this.initRadarChart(radarOpts({labelArr: title_hall, valueArr}));
      this.loading = false;

      if (this.threeTpl) {
        this.getQualityData();
      }
    },
    initRadarChart(opts) {
      if (!this.$refs.chart) {
        return;
      }
      const charts = echarts.init(this.$refs.chart);
      charts.clear();
      charts.setOption(opts);
    },
    initChart(opts) {
      if (!this.$refs.chartBar) {
        return;
      }
      const charts = echarts.init(this.$refs.chartBar);
      charts.clear();
      charts.setOption(opts);
    },
  },
  mounted() {
    this.getDataBySearch();
  },
};


function radarOpts({labelArr=[], valueArr=[]}) {
const gray = '#e0e0e0';
const darkGray = '#333';
const orange = '#F9713C';
const chartColor = '#6F95FC';
const title = {
  text: '暂无数据',
  left: 'center',
  top: 'center',
  textStyle: {
    color: '#aaa',
    fontSize: 20
  },
}

const radar =  {
    shape: 'circle',
    splitNumber: 5,
    nameGap: 16,
    center:['50%','50%'],
    name: {
        textStyle: {
          color: '#4379F9',
          fontSize: 12,
          borderRadius: 2,
          padding: 2
        }
    },
    splitLine: {
        lineStyle: {
            color: gray
        }
    },
    splitArea: {
        show: false
    },
    axisLine: {
        lineStyle: {
            color: gray
        }
    }
};

const radarSeries = {
  type: 'radar',
  itemStyle: {
    color: '#fcc147'
  },
  areaStyle: {
    opacity: 0.3
  },
  label: {
    show: true,
    position: 'inside',
    color: '#b10212'
  }
}

  const indicator = labelArr.map(o => ({name: o, max: 100}));
  let data = [];
  if(valueArr.length) {
    data = [{
      symbolSize: 6,
      label: {
        position: 'top',
        color: '#b10212',
        fontSize: 12,
        distance: 1,
      },
      value: valueArr,
      name: '-',
    }]
  }
  const option = {
      title: {
        ...title,
        show: !valueArr.length,
      },
      tooltip: {
        trigger: 'item',
        formatter: function (params, ticket) {
          return params.value.reduce((s, o, i) => (s = s + labelArr[i] + '：' + o + '<br/>'), '')
        }
      },
      //grid,
      calculable : true,
      radar: {
        ...radar,
        radius: '50%',
        indicator
      },
      series: [
          {
              ...radarSeries,
              data,
          },
      ]
  };

  return option;
}


function getBarOpts(list) {
  const legend = list.map((o) => o.title) || [];
  const labelArr = list[0].dataList.map((o) => o && o.label) || [];
  const series = list.map((o, i) => ({
    name: o.title,
    type: "bar",
    barCategoryGap: '10px',
    barMaxWidth: 10,
    data: o.dataList.map((o) => o && o.value),
  }));

  const barOpts = {
    color: ["#377EEA", "#FAC15C", "#11C16F"],
    grid: {
      left: '30px',
      bottom: '65px'
      
    },
    tooltip: {
      trigger: "axis",
      backgroundColor: '#ffffff',
      extraCssText: 'box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1)',
      textStyle: {
        color: "#000",
        fontSize: '8px',
      },
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      data: legend,
      icon: 'rect',
      itemHeight: 8,
      itemWidth: 10,
      textStyle: {
        color: "#000",
        fontSize: '8px',
      },
    },
    xAxis: [
      {
        type: "category",
        axisTick: { show: false },
        data: labelArr,
        axisLabel: {
          interval: 0,
          rotate: labelArr.length > 3 ? 40 : 0,
          textStyle: {
            color: "#000",
            fontSize: '8px',
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          textStyle: {
            color: "#000",
          },
        },
      },
    ],
    dataZoom: [
      {
        show: labelArr.length > 7,
        type: 'slider',
        maxValueSpan: 6,
        height: '10px',
        bottom: 1,
      },
    ],
    series,
  };

  return barOpts;
}
</script>
<style lang="less" scoped>

.box-drive{
  margin-top: 10px 0;
  border: 1px solid #fff;
  background: #fff;
  .tbl-drive{
    background: #EDEEEE;
    /deep/tr.noid {
      td {
        border-color: #969799;;
        color: #000;
        font-weight: normal;
        border-bottom: 1px solid #C3D5E4;
        padding: 0.2rem 2px;
      }
    }
    .tbl-name {
      color: #377EEA;
      padding: 0 2px;
    }
    /deep/.ant-table-thead > tr > th {
      background: #C8C9CC ;
      color: #000;
      font-size: 0.26666rem;
      border-left: 1px solid #969799;
      padding: 0.2rem;
      vertical-align: top;
      border-radius: 0;

      &:first-child {
        border: 0;
      }
    }
    /deep/.ant-table-row{
      &:hover td, td {
        background: #EDEEEE;
        color: #000;
        font-weight: 700;
        border-bottom: 1px solid #C3D5E4;
        font-size: 0.26666rem;
        padding: 0.2rem 0;
      }

      &:nth-child(2n) {
      }
    }
  }
}

</style>




